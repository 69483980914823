import React, { useEffect, useState } from 'react'
import SideMessage from '../../components/SideMessage';
import FilterTable from '../../components/FilterTable';
import PageHeading from '../../components/PageHeading';
import GetPaymentMaster from '../../APIs/Master/Payment/GetPaymentMaster';
import PostPaymentMaster from '../../APIs/Master/Payment/PostPaymentMaster';
import DeletePaymentMaster from '../../APIs/Master/Payment/DeletePaymentMaster';
import UpdatePaymentMaster from '../../APIs/Master/Payment/UpdatePaymentMaster';

function PaymentMaster() {
  let [paymentList, setPaymentList] = useState([]);
  let [rowId, setRowId] = useState(0);
  let [isShowModal, setIsshowModel] = useState(1);
  let [updatebool, setUpdatebool] = useState(0);
  let [loader, setLoader] = useState(1)
  let [sendForm, setSendForm] = useState({
    name: "",
    userId: window.userId,
  });

  let [sideToster, setSideToster] = useState({
    show: 0,
    message: '',
    type: ''
  })

  let handleChange = (e) => {
    document.getElementById("errPayment").style.display = "none";
    let name = e.target.name;
    let value = e.target.value;
    setSendForm({ ...sendForm, [name]: value })
  }

  let getPaymentList = async () => {
    const response = await GetPaymentMaster()
    if (response.status === 1) {
      setPaymentList(response.responseValue);
    }
  }


  let handleSave = async () => {
    if (sendForm.name === '' || sendForm.name.trim() === '') {
      document.getElementById("errPayment").innerHTML = "Enter payment name";
      document.getElementById("errPayment").style.display = "block";
    }
    else {
      const response = await PostPaymentMaster(sendForm);
      if (response.status === 1) {
        setSideToster({
          message: 'Data Save',
          show: 1,
          type: 'Success'
        })
        // setLoader(0)
        getPaymentList();
        handleClear();
      } else {
        // setLoader(0)
        setSideToster({
          show: 1,
          message: response.responseValue,
          type: 'Alert'
        })
      }
    }
  }

  let handleUpdate = (id, name, userId) => {
    setUpdatebool(1);
    setSendForm(sendForm => ({
      ...sendForm,
      "id": id,
      "name": name,
      "userId": userId,
    }))
    document.getElementById("name").value = name;
  }

  let saveUpdate = async () => {
    if (sendForm.name === '' || sendForm.name.trim() === '') {
      document.getElementById("errPayment").innerHTML = "Enter payment name";
      document.getElementById("errPayment").style.display = "block";
    }
    else {
      const response = await UpdatePaymentMaster(sendForm);
      if (response.status === 1) {
        setSideToster({
          message: 'Data Updated',
          show: 1,
          type: 'Success'
        })
        // setLoader(0)
        getPaymentList();
        handleClear();
      } else {
        // setLoader(0)
        setSideToster({
          show: 1,
          message: response.responseValue,
          type: 'Alert'
        })
      }
    }
  }

  let handleDelete = async () => {
    const response = await DeletePaymentMaster(rowId);
    if (response.status === 1) {
      setIsshowModel(0);
      setSideToster({
        message: 'Data Deleted',
        show: 1,
        type: 'Success'
      })
      getPaymentList();
    }
    else {
      setSideToster({
        show: 1,
        message: response.responseValue,
        type: 'Alert'
      })
    }
  }

  let handleClear = () => {
    setSendForm({ ...sendForm, name: "" });
    document.getElementById("errPayment").style.display = "none";
  };

  useEffect(() => {
    getPaymentList();
  }, []);
  return (
    <>
      <div className="row">
        <PageHeading pagename="Payment Master" />
        <div className="row container-fluid landformconatinermaster p-4">
          <div className="col col-md-4 pb-2_ pb-md-0">
            <div className="d-flex flex-column gap-1">
              <label className="form-label">Payment Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter payment name"
                onChange={handleChange}
                value={sendForm.name}
                className="form-input-box"
              />
              <small
                id="errPayment"
                className="form-text text-danger"
                style={{ display: "none" }}
              ></small>
            </div>
          </div>
          <div className="col col-md-4  savebtn">
            {updatebool === 0 ?
              <>
                <button
                  type="button"
                  className='btn export-save-btn'
                  onClick={handleSave}
                >
                  <i class="bi bi-floppy"></i> Save
                </button>
                &nbsp;
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  onClick={handleClear}
                >
                  <i class="bi bi-x-lg"></i> Clear
                </button>
              </>
              :
              <>
                <button
                  type="button"
                  className='btn export-save-btn'
                  onClick={saveUpdate}
                >
                  <i class="bi bi-floppy"></i> Update
                </button>
                &nbsp;
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  onClick={() => { setUpdatebool(0); handleClear() }}
                >
                  <i class="bi bi-x-lg"></i> Clear
                </button>
              </>
            }
          </div>

          <div className="row container-fluid landformconatiner p-4 mt-2">
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <PageHeading pagename="Payment Master List" />
              <FilterTable />
            </div>
            <div className="pt-4">
              <table class="table">
                <thead>
                  <tr className="table-head">
                    <th scope="col">#</th>
                    <th scope="col">Payment Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {paymentList &&
                    paymentList.map((val, ind) => {
                      return (
                        <tr key={ind}>
                          <th scope="row">{ind + 1}</th>
                          <td>{val.name}</td>
                          {/* <td>
                            <button type="button" class="btn btn-outline-warning btn-sm" onClick={() => { handleUpdate(val.id, val.name) }}>
                              <i class="bi bi-pencil-square"></i>
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              class="btn btn-outline-danger btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <i class="bi bi-trash3" onClick={() => { (setRowId(val.id)) }}></i>
                            </button>
                          </td> */}
                          <td >
                            <div class="dropdown dropend">
                              <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                              <ul className="dropdown-menu mr-3">
                                <li>
                                  <span className="dropdown-item" onClick={() => { handleUpdate(val.id, val.name) }}>Edit</span>
                                </li>
                                <li>
                                  <span className="dropdown-item" onClick={() => { setRowId(val.id) }}>Delete</span>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>



      {/* <!-- Modal --> */}
      {isShowModal === 1 ? <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1
                class="modal-title fs-5 pt-3"
                id="staticBackdropLabel"
                style={{ color: "red" }}
              >
                Alert{" "}
                <i
                  class="bi bi-exclamation-triangle"
                  style={{ fontSize: "18px" }}
                >

                </i>
              </h1>

            </div>
            <div class="modal-body pt-1">
              <h6 className="pb-3">______________________________________________________________________</h6>
              Are you sure you want to delete ?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleDelete}>
                Yes
              </button>
            </div>
          </div>
        </div>

      </div>
        : ''}

      {/* <!-- Modal End --> */}
      {/* {
                loader === 1 ? <Loader show={loader} /> : ""
            } */}

      {
        sideToster.show === 1 ?
          <SideMessage handleShow={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
          : ""
      }
    </>
  )
}

export default PaymentMaster
import React, { Children, useEffect, useRef, useState } from 'react'
import pdfIcon from "../assets/images/pdfIcon.png"
import { PDFDocument } from 'pdf-lib';
import Loader from './Loader';

export default function FileUpload(props) {
    let [count, setCount] = useState(0);
    const didMountRef = useRef(0);
    let [showModal, setShowModal] = useState(0)
    let [deleteId, setDeleteId] = useState(null)
    const [loader, setLoader] = useState(0)

    let handleFileUpload = async (e) => {
        if (e.target.files[0] !== "") {
            if(e.target.files[0].type === 'application/pdf')
            {
                console.log("enter")
                setLoader(1)
                let compressedFile = await compressPDF(e.target.files[0])
                props.setAllImage([...props.allImage, compressedFile]);
                setLoader(0)
            }
            else{
                props.setAllImage([...props.allImage,e.target.files[0]]);
            }
           
            let div = document.createElement('div');
            div.className = "image-div";
            div.id = "div" + count;
            div.name = "new" + count

            let btn = document.createElement('button');
            btn.innerHTML = "<span>X</span>";
            btn.className = "div-btn";
            btn.id = "btn" + count;
            btn.addEventListener('click', (e) => { handleClick(e) });

            let img = document.createElement('img');
            img.id = "img" + count;
            img.width = 250;
            img.height = 200;
            img.className = "div-image";

            // Check if the file is a PDF
            if (e.target.files[0].type === 'application/pdf') {
                // Set a PDF icon or thumbnail
                img.src = pdfIcon;
            } else {
                // Set the source to the image data URL
                img.src = URL.createObjectURL(e.target.files[0]);
            }

            div.appendChild(img);
            div.appendChild(btn);
            document.getElementById("showImage").appendChild(div);
            setCount(count + 1);
        }
    };

    let handleClick = (e) => {
        setDeleteId(e)
        setShowModal(1)

    };

    let handleDelete = () => {
        let e = deleteId
        let id = e.target.closest('div').id;
        let name = e.target.closest('div').name;
        document.getElementById(id).remove();
        const numericIndex = id.search(/\d/);
        const prefix = id.slice(0, numericIndex);
        const numericPart = id.slice(numericIndex);
        let temp = [...props.allImage];
        let deletedImg = temp[numericPart]
        console.log("delete", deletedImg, numericPart, temp[numericPart])
        if (deletedImg !== undefined) {
            if (!isNaN(name)) {
                props.setDeletedImages([...props.deletedImages, Number(name)])
            }
        }
        delete temp[numericPart];
        temp = temp.filter(subarray => subarray.length !== 0);
        setDeleteId(null)
        props.setAllImage([...temp]);
        setShowModal(0)
        // console.log("temp", temp)
    }


    useEffect(() => {
        if (props.allImage.length !== 0) {
            if (didMountRef.current === 1) {
                let counts = count;
                props.allImage.map((val, ind) => {
                    if (val.fileUrl !== undefined) {
                        let div = document.createElement('div');
                        div.className = "image-div";
                        div.id = "div" + counts;
                        div.name = val.id
                        let btn = document.createElement('button');
                        btn.innerHTML = "<span>X</span>";
                        btn.className = "div-btn";
                        btn.id = "btn" + counts;
                        btn.addEventListener('click', (e) => { handleClick(e) });

                        let img = document.createElement('img');
                        img.id = "img" + counts;
                        img.width = 250;
                        img.height = 200;
                        img.className = "div-image";

                        // Check if the file is a PDF
                        if (val.fileName.split(".")[1] === 'pdf') {
                            // Set a PDF icon or thumbnail
                            img.src = pdfIcon;
                        } else {
                            // Set the source to the image URL
                            img.src = val.fileUrl;
                        }

                        div.appendChild(img);
                        div.appendChild(btn);
                        document.getElementById("showImage").appendChild(div);
                        counts = counts + 1;
                    }
                });
                setCount(counts);
                didMountRef.current = 2;
            }
        } else {
            var parentDiv = document.getElementById("showImage");
            while (parentDiv.firstChild) {
                parentDiv.removeChild(parentDiv.firstChild);
            }
            didMountRef.current = 1;
        }

    }, [props]);


    return (<>


        <div className='row pb-4 pt-3' >
            <div className='col col-4'>
                <div className='d-flex flex-column gap-1'>
                    <label className='form-label'>{props.name}</label>
                    <div className='d-flex flex-column  align-items-center fileuploadbox pt-2'>
                        <i class="bi bi-cloud-upload-fill"></i>
                        <span className='gardiantext'>Drag & drop your file here</span>
                        <span className='gardiantext'>OR</span>
                        <span className='gardiantext'>Click</span>
                        <input type='file' className='fileinput' onChange={handleFileUpload} />
                    </div>
                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar" id="progressbar" style={{width: "0%"}}></div>
                    </div>
                </div>
            </div>
            <div className='col col-8 pt-4' >
                {/* <label className='form-label'>Uploaded Images</label> */}
                <div className='d-flex flex-row gap-4' id="showImage" style={{ overflowX: "auto" }}>

                </div>
            </div>
        </div>

        {/* modal */}
        <div class={`modal d-${showModal === 1 ? "block" : "none"}`} id="exampleModal" tabindex="-1">
            <div class="modal-dialog " style={{ width: "250px" }}>
                <div class="modal-content ">
                    <div class="modal-header">
                        {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Delete</h1> */}
                        <button type="button" class="btn-close" onClick={() => { setShowModal(0); setDeleteId(null) }}></button>
                    </div>
                    <div class="modal-body">
                        <div className='d-flex flex-row justify-content-center'>
                            <div className='sav-circle d-flex flex-row justify-content-center align-items-center'>
                                <svg fill="#ff0000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="-48.24 -48.24 578.91 578.91" xmlSpace="preserve" stroke="#ff0000" transform="matrix(1, 0, 0, 1, 0, 0)">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.9648559999999999"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g>
                                            <g> <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z"></path> <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"></path>
                                                <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"></path>
                                                <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <h1 class="modal-title fs-5 text-center pt-3" id="exampleModalLabel">Do You Want Delete?</h1>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={() => { setShowModal(0); setDeleteId(null) }}>No</button>
                        <button type="button" class="btn btn-primary" onClick={handleDelete}>Yes</button>
                    </div>
                </div>
            </div>
        </div>

        {loader === 1?<Loader show={loader}/>:""}
    </>

    )
}


const compressPDF = async (file) => {
    if (file.type !== 'application/pdf') {
        throw new Error('The selected file is not a PDF.');
    }
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const compressedPdf = await pdfDoc.save({ useObjectStreams: true });
    console.log("Compressed PDF Size:", new Blob([compressedPdf], { type: 'application/pdf' }).size / 1024, "KB");
    return new Blob([compressedPdf], { type: 'application/pdf' });
};


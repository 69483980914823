import React, { useEffect, useState } from 'react'
import FileUpload from '../../components/FileUpload'
import GetLandDocumentTypeList from '../../APIs/Master/LandDocumentTypeMaster/GetLandDocumentTypeList'
import InsertLandReferenceDocument from '../../APIs/LandProof/InsertLandReferenceDocument'
import Loader from '../../components/Loader'
import GetLandReferenceDocument from '../../APIs/LandProof/GetLandReferenceDocument'
import UpdateLandReferenceDocument from '../../APIs/LandProof/UpdateLandReferenceDocument'
import SideMessage from '../../components/SideMessage'
import FileUploadAPI from '../../assets/js/fileUpload'

export default function AddProof(props) {
  let [landDocumentTypeList, setLandDocumentTypeList] = useState([])
  let [allImage, setAllImage] = useState([])
  let [documentTypeId, setDocumentTypeId] = useState(0)
  let [loader, setLoader] = useState(0)
  let [isUpdate, setIsUpdate] = useState(0)
  let [sideToster, setSideToster] = useState({
    show: 0,
    message: '',
    type: ''
  })
  let [deletedImages, setDeletedImages] = useState([])


  let getData = async () => {
    let respLandProof = await GetLandDocumentTypeList()
    if (respLandProof.status === 1) {
      setLandDocumentTypeList(respLandProof.responseValue)
    }
  }
  let getOldImage = async (id) => {
    let resp = await GetLandReferenceDocument(id);
    if (resp.status === 1 && resp.responseValue.length !== 0) {
      let data = resp.responseValue[0]
      let allData = resp.responseValue.map(value => value.landReferenceDocumentFiles[0])
      console.log("data", allData)
      setAllImage(allData)
      setDocumentTypeId(data.documentTypeId)
      setIsUpdate(1)
    }
    else {

    }
  }
  let varification = () => {
    console.log("documentTypeId", documentTypeId, "allImage", allImage, documentTypeId !== 0 , allImage.length !== 0 )
    if (documentTypeId !== 0 && allImage.length !== 0) {
      return [true, ""]
    }
    else if (documentTypeId === 0) {
      return [false, "Please Select Document Type"]

    }
    else if (allImage.length === 0) {
      return [false, "Please Select Image"]
    }
    else{
      return [false, "Somthing Wrong"]

    }
  }
  let handleSave = async () => {
    let formdata = new FormData()
    allImage.map((val, ind) => {
      formdata.append("DocumentTypeId", documentTypeId)
      formdata.append("DocumentFiles", val)
      formdata.append("UserId", window.userId)
    })
    let verificationresp = varification()
    if (verificationresp[0]) {
      setLoader(1)
      let response = await FileUploadAPI(formdata, window.RealestateService + `/api/Land/InsertLandReferenceDocument?LandId=${JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id}`)
      let resp = response.data 
      // let resp = await InsertLandReferenceDocument(formdata, JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id)
      if (resp.status === 1) {
        setLoader(0)
        props.handleActiveTab(3)

        console.log('resp', resp)
      }
      else {
        setLoader(0)
        setSideToster({
          show: 1,
          message: resp.responseValue,
          type: 'Alert'
        })
      }
    }
    else {
      setSideToster({
        show: 1,
        message: verificationresp[1],
        type: 'Alert'
      })
    }


  }
  let handleUpdate = async () => {
    setLoader(1)
    let formdata = new FormData()
    allImage.map((val, ind) => {
      formdata.append("DocumentTypeId", documentTypeId)
      formdata.append("DocumentFiles", val)
      formdata.append("UserId", window.userId)
    })

    if (deletedImages.length !== 0) {
      deletedImages.map((val, ind) => {
        formdata.append("landReferenceDocumentIds", val);
      })
    }

    // console.log("delete Id", deletedImages)
    else {
      formdata.append("landReferenceDocumentIds", [0]);

    }
    let response = await FileUploadAPI(formdata, window.RealestateService + `/api/Land/UpdateLandReferenceDocument?LandId=${JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id}`)
    let resp = response.data 
    // let resp = await UpdateLandReferenceDocument(formdata, JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id)
    if (resp.status === 1) {
      setLoader(0)
      props.handleActiveTab(3)

      console.log('resp', resp)
    }
    else {
      setLoader(0)
      setSideToster({
        show: 1,
        message: resp.responseValue,
        type: 'Alert'
      })
    }

  }
  useEffect(() => {
    getData()
    let landata = JSON.parse(window.sessionStorage.getItem("ProjectData")).landData
    if (landata) {
      getOldImage(landata.id)
    }
  }, [])

  return (
    <>
      <div className='container-fluid landformconatiner p-4' id="conatinerId">
        <div className='wrapform'>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Select Proof Type</label>
            <select className='form-input-box form-select pe-5' value={documentTypeId} onChange={(e) => { setDocumentTypeId(e.target.value) }}>
              <option value="-1">--Select Proof Type--</option>
              {
                landDocumentTypeList && landDocumentTypeList.map((val, ind) => {
                  return (
                    <option value={val.id}>{val.documentType}</option>
                  )
                })
              }
            </select>
          </div>

          {/* <div className='row pb-4'>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Select Proof Type</label>
              <select className='form-input-box pe-5' onChange={(e) => { setDocumentTypeId(e.target.value) }}>
                <option value="-1">--Select Proof Type--</option>
                {
                  landDocumentTypeList && landDocumentTypeList.map((val, ind) => {
                    return (
                      <option value={val.id}>{val.documentType}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </div> */}
        </div>

        <FileUpload setDeletedImages={setDeletedImages} deletedImages={deletedImages} setAllImage={setAllImage} allImage={allImage} name="Select Proof Documents"/>
      </div>
      <div className='d-flex flex-row justify-content-md-end'>
        {

          isUpdate === 0 ?

            <button className='btn export-save-btn' onClick={handleSave}>Save Proof & Next</button>
            :

            <button className='btn export-save-btn' onClick={handleUpdate}>Update Proof & Next</button>
        }
      </div>
      <Loader show={loader} />
      {
        sideToster.show === 1 ?
          <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
          : ""
      }
    </>

  )
}

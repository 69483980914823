// const FileUpload = (formData, url) => {
//     return new Promise((resolve, reject) => {
//         const xhr = new XMLHttpRequest();

//         // Monitor the progress of the upload
//         xhr.upload.addEventListener("progress", (e) => {
//             if (e.lengthComputable) {
//                 const percentComplete = Math.round((e.loaded / e.total) * 100);
//                 const progressBar = document.getElementById("progressbar");
//                 if (progressBar) {
//                     progressBar.style.width = percentComplete + "%";
//                     progressBar.textContent = percentComplete + "%";
//                 }
//             }
//         });

//         // Handle the response
//         xhr.addEventListener("load", () => {
//             if (xhr.status === 200) {
//                 console.log("File uploaded successfully!");
//                 resolve(xhr.responseText); // Resolve with the response text
//             } else {
//                 console.log(`Upload failed: ${xhr.statusText}`);
//                 reject(`Upload failed: ${xhr.statusText}`); // Reject with the error message
//             }
//         });

//         // Handle errors
//         xhr.addEventListener("error", () => {
//             console.log("An error occurred while uploading the file.");
//             reject("An error occurred while uploading the file.");
//         });

//         // Handle upload abort
//         xhr.addEventListener("abort", () => {
//             console.log("Upload aborted.");
//             reject("Upload aborted.");
//         });

//         // Send the request
//         xhr.open("POST", url, true);
//         xhr.send(formData);
//     });
// };

import axios from 'axios';
const FileUploadAPI = async (formData, url) => {
    const progressBar = document.getElementById("progressbar");
    let response ="";
    await axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: event => {
            if (progressBar) {
                const percentComplete = Math.round((100 * event.loaded) / event.total)
                progressBar.style.width = percentComplete + "%";
                progressBar.textContent = percentComplete + "%";
            }

        }
    }).then(res => response = res).catch(err => console.log(err))
    return response
};

export default FileUploadAPI;
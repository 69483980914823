import React, { useState } from 'react'
import SideMessage from './SideMessage';

export default function InputBoxNumber(props) {

    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })

    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value.replace(/[^0-9.]/g, '');
        if ((value.match(/\./g) || []).length > 1) {
            // value = value.slice(0, value.lastIndexOf('.')) + value.slice(value.lastIndexOf('.') + 1); // Remove extra periods
            return
        }


        if (e.target.value.length !== 0) {
            if (value.length <= props.range) {
                if (props.min === undefined || value >= props.min && value <= props.max) {
                    if (value !== "") {
                        if (props.isFun !== undefined) {
                            
                            if (value.includes(".")) {
                                if (value.length === 1) {
                                    props.setSendForm(e, props.index, "00" + value)
                                }
                                else {
                                    props.setSendForm(e, props.index, value)
                                }
                            }
                            else {
                                props.setSendForm(e, props.index, value)
                            }
                        }
                        else {
                            if (value.includes(".")) {
                                if (value.length === 1) {
                                    props.setSendForm((data) => ({ ...data, [name]: "00" + value }))
                                }
                                else {
                                    props.setSendForm((data) => ({ ...data, [name]: value }))
                                }
                            }
                            else {

                                props.setSendForm((data) => ({ ...data, [name]: value }))
                            }
                        }
                    }
                    else {

                        // alert("Don't use symbol", e.target.value.length)
                        // setSideToster({
                        //     show: 1,
                        //     message: "Don't use symbol " + e.target.value,
                        //     type: 'Alert'
                        // })
                    }
                }
                else {
                    setSideToster({
                        show: 1,
                        message: `Enter between ${props.min} - ${props.max}`,
                        type: 'Alert'
                    })
                }

            }
            else {
                setSideToster({
                    show: 1,
                    message: `Only Enter ${props.range} digits`,
                    type: 'Alert'
                })
            }
        }
        else {
            if (props.isFun !== undefined) {
                props.setSendForm(e, props.index)
            }
            else {
                props.setSendForm((data) => ({ ...data, [name]: value }))
            }
            // props.setSendForm((data) => ({ ...data, [name]: "" }))
        }
    }

    return (
        <>
            <input type="text" className='form-input-box form-control' name={props.name} value={props.value} id={props.id} onChange={handleChange} placeholder={props.placeholder} disabled={props.disabled} />
            {
                sideToster.show === 1 ?
                    <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
        </>
    )
}
